import config from '../utils/env';

interface UrlConstants {
  mynib: string;
  careers: string;
  newsAndMedia: string;
  getQuickQuote: (promoCode?: string) => string;
  getQuoteWidgetUrl: string;
  cnJoin: string;
  contactUs: string;
  appleAppStore: string;
  googlePlayStore: string;
  nwoPortal: string;
  findAProvider: string;
  employerInsurance: string;
  employerInsuranceCallbackSection: string;
  travelInsurance: string;
  compareLifeAndLivingInsurance: string;
  lifeAndLivingInsurance: string;
  aboutLifeLivingInsurance: string;
  lifeLivingInsuranceContactUs: string;
  currentOfferLifeLivingInsurance: string;
  estimateYourCoverageLifeLivingInsurance: string;
  whyLifeLivingInsuranceCoverIsImportant: string;
  howToApplyLifeLivingInsurance: string;
  lifeAndLivingInsuranceWording: string;
  lifeAndLivingInsuranceFactSheet: string;
  lifeLivingHomeowners: string;
  temporaryAccidentalDeathCoverPDF: string;
  nonPharmac: string;
  bluesShareADay: string;
  bluesRugby: string;
  foundationContactForm: string;
  foundation: string;
  foundationProjectClearhead: string;
  foundationUniversityOfOtago: string;
  foundationPreventionPartnership: string;
  ngatiWhatuaOrakei: string;
  tenehenehenui: string;
  financialStrength: string;
  ultimateInsuranceDocument: string;
  ultimateLifeInsuranceBenefitSheet: string;
  ultimateTraumaInsuranceDocument: string;
  ultimateLifeInsuranceDocument: string;
  ultimateLifeInsurancePolicyDocument: string;
  ultimateTotalPermanentDisabilityInsuranceDocument: string;
  ultimateIncomeProtectionInsuranceDocumentAgreedValue: string;
  ultimateIncomeProtectionInsuranceDocumentIndemnity: string;
  ultimateMortgageProtectionInsuranceDocument: string;
  ultimateWaiverOfPremiumInsuranceDocument: string;
  ultimateMultiCoverDiscountForLifeAndLivingInsurance: string;
  ultimateMultiCoverDiscountForHealthInsurance: string;
  ultimateTraumaInsuranceBenefitSheet: string;
  ultimateIncomeProtectionInsuranceBenefitSheet: string;
  ultimateMortgageProtectionInsuranceBenefitSheet: string;
  ultimateWaiverOfPremiumInsuranceBenefitSheet: string;
  ultimateTotalPermanentDisabilityInsuranceBenefitSheet: string;
}

const LIFE_COVER_OPTIONS_PATH = '/life-cover-options';

export const urlConstants: UrlConstants = {
  mynib: `${config.portalUrl}`,
  careers: 'https://careers.nib.co.nz',
  newsAndMedia: '/news-and-media/news-details/free-health-and-dental-check-ups',
  getQuickQuote: (promoCode) =>
    promoCode ? `${config.dtcJoinUrl}?promoCode=${promoCode}` : `${config.dtcJoinUrl}`,
  getQuoteWidgetUrl: `${config.dtcJoinUrl}`,
  cnJoin: `${config.dtcJoinUrl}?locale=zh`,
  contactUs: 'https://health.nib.co.nz/contact-us',
  appleAppStore: 'https://apps.apple.com/us/app/my-nib-nz/id968982318',
  googlePlayStore: 'https://play.google.com/store/apps/details?id=nz.co.nib.mynib&hl=en',
  nwoPortal: 'https://www.health-iq.com.au/Portal/Member/login/nwo',
  findAProvider: '/find-a-provider',
  employerInsurance: 'https://health.nib.co.nz/business',
  employerInsuranceCallbackSection: 'https://health.nib.co.nz/business#callback_section_panel',
  travelInsurance: 'https://www.nibtravel.co.nz/',
  compareLifeAndLivingInsurance: '/life-cover-options',
  aboutLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/about-life-living-insurance`,
  currentOfferLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/special-offers`,
  estimateYourCoverageLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/estimate-your-cover`,
  whyLifeLivingInsuranceCoverIsImportant: `${LIFE_COVER_OPTIONS_PATH}/why-life-living-cover-is-important`,
  howToApplyLifeLivingInsurance: `${LIFE_COVER_OPTIONS_PATH}/how-to-apply`,
  lifeLivingInsuranceContactUs: `${LIFE_COVER_OPTIONS_PATH}/contact-us`,
  lifeLivingHomeowners: `${LIFE_COVER_OPTIONS_PATH}/for-homeowners`,
  ultimateInsuranceDocument:
    'https://assets.ctfassets.net/px2565zobjcl/kRbOe9JjtiZky2a3LLhzX/edf1e097bb8512c06a5bd5fb26e1fc41/nib-Ultimate_Insurance_Brochure-_Feb_2025.pdf',
  lifeAndLivingInsuranceWording:
    'https://assets.ctfassets.net/ja9v5o5o08yv/pLH4KfjIcr2gJ5I8GufTm/f4298598f74a1b027e5de9abfbea966b/Life_and_Living_Insurance_Cover_Wording_0623.pdf',
  lifeAndLivingInsuranceFactSheet:
    'https://assets.ctfassets.net/ja9v5o5o08yv/105PXD3LGZFWPdbvtoQ16h/79f5a9b0f3daa64c407124fe07028597/Life_and_Living_Insurance_Fact_Sheet_0623.pdf',
  lifeAndLivingInsurance: 'https://health.nib.co.nz/life-and-living-insurance',
  temporaryAccidentalDeathCoverPDF:
    'https://assets.ctfassets.net/ja9v5o5o08yv/43muUAabCb3p1U36ZRTbgt/e2b7ae66255187e6e50d52e7e61c4b15/Temporary_Accidental_Death_Cover_0623.pdf',
  nonPharmac: '/non-pharmac',
  bluesShareADay:
    '/news-and-media/news-details/nib-blues-share-a-day-in-the-life-as-a-professional-womens-rugby-player/',
  bluesRugby: 'https://www.blues.rugby/',
  foundationContactForm: 'https://www.nib.com.au/foundation/contact-us',
  foundation: 'https://www.nib.com.au/foundation/',
  foundationProjectClearhead: 'https://www.nib.com.au/foundation/project/clearhead-nz',
  foundationUniversityOfOtago: 'https://www.nib.com.au/foundation/project/university-of-otago-nz',
  foundationPreventionPartnership:
    'https://www.nib.com.au/foundation/prevention-partnerships-program',
  ngatiWhatuaOrakei: '/ngatiwhatuaorakei',
  tenehenehenui: '/tenehenehenui',
  financialStrength: '/about-nib/financial-advice#financial-strength-nibnz',
  ultimateTraumaInsuranceDocument:
    'https://assets.ctfassets.net/px2565zobjcl/17V1pXyinXXyxU5TaIrDlv/52c60a49a2b820e1759a4841afec0c66/ULL_Ultimate_Trauma_Ins_PD_Mar25.pdf',
  ultimateTotalPermanentDisabilityInsuranceDocument:
    'https://assets.ctfassets.net/px2565zobjcl/4paQ1CPBQHdCQ0XYNgPHCT/c3b8a29c216665a9424268894117ff37/ULL_Ultimate_TPD_PD_Mar25.pdf',
  ultimateIncomeProtectionInsuranceDocumentAgreedValue:
    'https://assets.ctfassets.net/px2565zobjcl/1xSm3dsmm9EUrSAS7bbEoz/66ffa6fbc77379cadbb15f639a22b5ae/ULL_Ultimate_IP_-_Agreed_Value_PD_Mar25.pdf',
  ultimateIncomeProtectionInsuranceDocumentIndemnity:
    'https://assets.ctfassets.net/px2565zobjcl/4cIKHaapwTjpDaFTQ4QFJu/b689ff7b242f037a65e8875fb6f379df/ULL_Ultimate_IP_-_Indemnity_PD_Mar25.pdf',
  ultimateMortgageProtectionInsuranceDocument:
    'https://assets.ctfassets.net/px2565zobjcl/6rVOaOR3Um3mfAAhokJP9f/8e3aed67a098ed187d95185fdd35283e/ULL_Ulatime_MP_Insurance_PD_Mar25.pdf',
  ultimateWaiverOfPremiumInsuranceDocument:
    'https://assets.ctfassets.net/px2565zobjcl/3fsb9NnmWSvnRyqR1Rgc8R/43b0734f4f63ad4e07265348a4379187/ULL_Ultimate_Waiver_of_Premium_PD_Mar25.pdf',
  ultimateLifeInsuranceDocument:
    'https://assets.ctfassets.net/px2565zobjcl/vjoS8Dn3HZd92FEZMjlXX/13aa22a9275861143ec8b7b25e2a3aba/ULL_Ultimate_Life_Insurance_PD_Mar25.pdf',
  ultimateMultiCoverDiscountForLifeAndLivingInsurance:
    'https://assets.ctfassets.net/px2565zobjcl/6wOUlcIBXcD9e3ppzUeIrc/43fc7e176d2cf4b4cf4e0d0e760786a6/78ec8441-7f6a-403d-a833-1ebfaef3c06d.pdf',
  ultimateMultiCoverDiscountForHealthInsurance:
    'https://assets.ctfassets.net/px2565zobjcl/3ObAvXczKPFZjp95S1TVA9/34308c0da0cf39c6d007ddddca4eb115/Health_and_Ultimate_Life_Bundled.pdf',
  ultimateLifeInsuranceBenefitSheet:
    'https://assets.ctfassets.net/px2565zobjcl/4Yh7LF8XaoxSnKi0FsMilK/ff12366bf7741f91ac80a4200505ed15/nib_Ultimate_Life_Insurance_BenefitSheet_A4.pdf',
  ultimateTraumaInsuranceBenefitSheet:
    'https://assets.ctfassets.net/px2565zobjcl/3Lsrhb976omrloUPOSC11D/b287b2d5236bbca3d4bddb9e6f8613a2/nib_Ultimate_Trauma_Insurance_BenefitSheet_Digital.pdf',
  ultimateIncomeProtectionInsuranceBenefitSheet:
    'https://assets.ctfassets.net/px2565zobjcl/7l2iN14N2O2g8pJ5t7dsNI/c4315d2af87c694f3875fd1a1d4e5db0/nib_Ultimate_Income_Protection_Insurance_Digital.pdf',
  ultimateMortgageProtectionInsuranceBenefitSheet:
    'https://assets.ctfassets.net/px2565zobjcl/55P7cc4avPxUd41sBbnFFq/3c71b37b1842708c9819819b936ed50f/nib_Ultimate_Mortgage_Protection_Insurance_BenefitSheet_A4.pdf',
  ultimateWaiverOfPremiumInsuranceBenefitSheet:
    'https://assets.ctfassets.net/px2565zobjcl/1QbrfUk1SKz2RsejkMQ5kP/3ee02160f7dc56399363cf938b1d2398/nib_Ultimate_WoP_BenefitSheet_A4.pdf',
  ultimateTotalPermanentDisabilityInsuranceBenefitSheet:
    'https://assets.ctfassets.net/px2565zobjcl/6EDA00sVTVSNIAM573T6dZ/0c7aaa2bfd92f5499156b83aa2acb54a/nib_Ultimate_TPD_Insurance_BenefitSheet_A4.pdf',
  ultimateLifeInsurancePolicyDocument:
    'https://assets.ctfassets.net/px2565zobjcl/vjoS8Dn3HZd92FEZMjlXX/13aa22a9275861143ec8b7b25e2a3aba/ULL_Ultimate_Life_Insurance_PD_Mar25.pdf',
};
